import React from 'react';
import { MediumPrimaryButton } from '../../components/Buttons';
import EmptyState from '../../components/EmptyState';
import windowService from '../../services/windowService';
import { Grid } from '../../StyleGuide';

export const SessionNotFound = ({ scheduleRoute }: { scheduleRoute: string }) => {
  return (
    <EmptyState title="We are unable to find your session" description="Please return to the schedule and try again">
      <MediumPrimaryButton style={{ marginTop: Grid._4 }} onClick={() => windowService.redirectTo(scheduleRoute)}>
        Return to Schedule
      </MediumPrimaryButton>
    </EmptyState>
  );
};
