import curriculumService from '../../services/curriculumService';
import { SessionNotFoundError } from '../models/error';
import { Session } from '../models/session';

export const getSessionFromSchedule = (curriculumId: string, sessionId: string) =>
  // TODO: replace service call once getCurriculumSchedule has been brought into feature
  curriculumService.getCurriculumSchedule(curriculumId).then(({ sessions }: { sessions: Session[] }) => {
    const session = sessions.find((session: Session) => session.sessionId === sessionId);
    return session || Promise.reject(new SessionNotFoundError(`Unable to find session ${sessionId} in schedule`));
  });
