import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DeemphasizedBadge } from '../components/Badge';
import { SmallRoundedIconButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import {
  CollectionImgContainer,
  CollectionImgPlaceholder,
  Container,
  FullWidthCard,
  Image,
  InlineList,
} from '../components/Layout';
import { ListItemFlexRow } from '../components/ListItemFlexRow';
import LoadingState from '../components/LoadingState';
import { SessionDateHeaderAndDetails, SessionDescription } from '../components/SessionElements';
import useCurriculum from '../hooks/useCurriculum';
import curriculumService from '../services/curriculumService';
import windowService from '../services/windowService';
import { Breakpoints, Grid } from '../StyleGuide';
import { getSessionFromSchedule } from './api/getSessionFromSchedule';
import BibleStudyNavigation from './components/BibleStudyNavigation';
import { SessionNotFound } from './components/SessionNotFound';
import { MaterialList, MaterialListItem } from './MaterialList';
import { Collection } from './models/collection';
import { SessionNotFoundError } from './models/error';
import { Session } from './models/session';

const PageContainer = styled(Container)`
  padding: 0 0 ${Grid._5} 0;
`;

const Collections = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 ${Grid._4};
`;

const MaterialsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Grid._3};
  h3 {
    margin: 0;
  }
  select {
    max-width: 130px;
  }
`;

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 0 0 ${Grid._4};
  }
`;

const CollectionDetails = styled(Details)`
  h2 {
    margin: 0 0 ${Grid._4} 0;
  }
`;

type UrlParams = {
  curriculumId: string;
  sessionId: string;
};

type State = {
  isLoading: boolean;
  error?: Error;
  session?: Session;
  collections?: Collection[];
};

export default function ManageBibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId } = useParams<UrlParams>();
  const [data, setData] = useState<State>({ isLoading: true });

  const allMaterialsCollectionId = 'all-session-materials';

  const scheduleRoute = `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`;
  const crumbs = [
    { name: 'Curriculum', route: '#/bible-studies' },
    { name: `${brand.name}: ${ageCategory}`, route: scheduleRoute },
    { name: data.session?.name ?? '' },
  ];

  useEffect(() => {
    const getSession = getSessionFromSchedule(curriculumId, sessionId);
    const getCollections = curriculumService.getCollections(curriculumId);
    const getAllSessionMaterials = curriculumService.getAllSessionMaterials(curriculumId, sessionId);

    Promise.all([getSession, getCollections, getAllSessionMaterials])
      .then(([session, collectionsResponse, allSessionMaterials]) => {
        const collections = collectionsResponse
          .map((c: { collection: Collection }) => c.collection)
          .map((c: Collection) => ({
            ...c,
            materials: curriculumService.filterSessionMaterials(c.collectionId, allSessionMaterials),
          }));

        if (allSessionMaterials.length > 0) {
          const allMaterialsCollection = {
            collectionId: allMaterialsCollectionId,
            name: 'All Session Materials',
            materials: allSessionMaterials,
          };
          collections.push(allMaterialsCollection);
        }

        setData({ isLoading: false, session, collections });
      })
      .catch((error: Error) => {
        console.error(error);
        setData({ isLoading: false, error });
      });
  }, []);

  const downloadFile = (type: string, mediaId: string) => {
    const downloadUrl = `${window.getApiUrl()}/curriculum-storage/${
      type === 'video' || type === 'audio' ? 'downloadvideo' : 'files'
    }/${mediaId}`;
    window.open(downloadUrl);
  };

  const handleMaterialClick = (material: any) => {
    if (material.materialType === 'podcast' && material.providerData) {
      windowService.openUrl(material.providerData);
    } else {
      downloadFile(material.materialType, material.mediaId);
    }
  };

  return (
    <>
      <BibleStudyNavigation crumbs={crumbs} curriculumId={curriculumId} activeTab="schedule" />
      <FullWidthCard>
        <PageContainer>
          {data.isLoading ? (
            <LoadingState />
          ) : data.error instanceof SessionNotFoundError ? (
            <SessionNotFound scheduleRoute={scheduleRoute} />
          ) : data.error || !data.session || !data.collections ? (
            <ErrorMessage />
          ) : (
            <div data-tracking-section="session" data-tracking-id={data.session.name}>
              <SessionDateHeaderAndDetails session={data.session}>
                <InlineList content={'•'}>
                  <li>{data.session.issueName}</li>
                  <li>Session {data.session.sessionNumber}</li>
                  <li>{data.session.getAvailableThroughMessage('MMMM d')}</li>
                </InlineList>
              </SessionDateHeaderAndDetails>
              <SessionDescription
                dangerouslySetInnerHTML={{ __html: data.session.description ?? '' }}
              ></SessionDescription>
              <Collections>
                {data.collections.map((collection, i) => {
                  const isAllSessionMaterials = collection.collectionId === allMaterialsCollectionId;
                  return (
                    <ListItemFlexRow
                      key={i}
                      data-tracking-component="collection"
                      data-tracking-id={collection.name}
                      align="normal"
                    >
                      <CollectionImgContainer>
                        {collection.image ? (
                          <Image src={collection.image} alt={`${brand.name}: ${collection.name}`} />
                        ) : !isAllSessionMaterials ? (
                          <CollectionImgPlaceholder />
                        ) : null}
                      </CollectionImgContainer>
                      <CollectionDetails>
                        <h2>{collection.name}</h2>
                        {!isAllSessionMaterials && (
                          <MaterialsHeader>
                            <h3>Materials</h3>
                          </MaterialsHeader>
                        )}

                        {!collection.materials?.length ? (
                          <p>This collection does not have any materials in this session.</p>
                        ) : (
                          <MaterialList>
                            {collection.materials
                              .sort((a, b) => Number(b.hasAccess) - Number(a.hasAccess))
                              .map((material, i) => {
                                const isPlus = material.package === 'Plus';
                                const materialLabel =
                                  material.materialType === 'download' ? 'document' : material.materialType;
                                return (
                                  <MaterialListItem
                                    key={i}
                                    data-tracking-component="session-material"
                                    data-tracking-id={material.name}
                                    disabled={!material.hasAccess}
                                    onClick={material.hasAccess ? () => handleMaterialClick(material) : undefined}
                                  >
                                    <div className="material-text">
                                      <p className="material-label">{materialLabel}</p>
                                      <p className="material-name">
                                        {material.name}
                                        {isPlus && (
                                          <DeemphasizedBadge style={{ marginLeft: '8px' }}>Plus</DeemphasizedBadge>
                                        )}
                                      </p>
                                      {isAllSessionMaterials && (
                                        <InlineList content={','}>
                                          {material.series?.map(c => (
                                            <li key={c.collectionId}>{c.name}</li>
                                          ))}
                                        </InlineList>
                                      )}
                                    </div>
                                    {material.hasAccess && (
                                      <SmallRoundedIconButton>
                                        {material.materialType === 'podcast' ? (
                                          <i className="fa fa-external-link-alt"></i>
                                        ) : (
                                          <i className="fas fa-download fa-fw"></i>
                                        )}
                                      </SmallRoundedIconButton>
                                    )}
                                  </MaterialListItem>
                                );
                              })}
                          </MaterialList>
                        )}
                      </CollectionDetails>
                    </ListItemFlexRow>
                  );
                })}
              </Collections>
            </div>
          )}
        </PageContainer>
      </FullWidthCard>
    </>
  );
}
