import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import { Button, PrimaryButton } from '../components/Buttons';
import ConfirmationModal from '../components/ConfirmationModal';
import ErrorMessage from '../components/ErrorMessage';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { Container, FullWidthCard } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import { ModalWarningList } from '../components/ModalElements';
import { SessionDateHeaderAndDetails, SessionDescription } from '../components/SessionElements';
import { SessionTimelineProvider } from '../contexts/SessionTimelineContext';
import useCurriculum from '../hooks/useCurriculum';
import useModal from '../hooks/useModal';
import alertService from '../services/AlertService';
import sessionTimelineService from '../services/sessionTimelineService';
import windowService from '../services/windowService';
import { Grid } from '../StyleGuide';
import { handleError } from '../utils/apiUtils';
import { ymdFromDate } from '../utils/dateUtils';
import { getSessionFromSchedule } from './api/getSessionFromSchedule';
import BibleStudyNavigation from './components/BibleStudyNavigation';
import { SessionNotFound } from './components/SessionNotFound';
import { SessionNotFoundError } from './models/error';
import { Session } from './models/session';
import { Timeline } from './models/timeline';
import DownloadPresentationMediaButton, {
  getPresentationMediaArchiveName,
} from './timeline/DownloadPresentationMediaButton';
import SessionTimeline from './timeline/SessionTimeline';
import SessionTimelinePrintStyles from './timeline/SessionTimelinePrintStyles';

type UrlParams = { curriculumId: string; sessionId: string; timelineId: string; originalTimelineId: string };

type State = {
  isLoading: boolean;
  error?: Error;
  timeline?: Timeline;
  session?: Session;
};

const PreviewButton = ({ url }: { url: string }) => (
  <PrimaryButton onClick={() => windowService.openRoute(url, true)}>
    <i className="fas fa-external-link-alt" /> View as Group Leader
  </PrimaryButton>
);

export default function ManageBibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId, timelineId, originalTimelineId } = useParams<UrlParams>();
  const [data, setData] = useState<State>({
    isLoading: true,
  });
  const user = useUser();
  const presentationMediaArchiveName = getPresentationMediaArchiveName(
    brand.name,
    ageCategory,
    ymdFromDate(data.session?.scheduledDate),
    data.timeline?.name
  );

  const scheduleRoute = `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`;
  const crumbs = [
    { name: 'Curriculum', route: '#/bible-studies' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: scheduleRoute,
    },
    { name: 'Leader Guide' },
  ];

  const redirectToResetTimeline = (resetTimelineId: string) => {
    windowService.redirectTo(
      `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}/${sessionId}/timeline/${originalTimelineId}/${resetTimelineId}`
    );
  };

  const submitTimelineResetAndRedirect = () => {
    sessionTimelineService
      .deleteCustomizedTimeline(originalTimelineId, timelineId, user?.lastSelectedAccount, user?.userId)
      .then(() =>
        sessionTimelineService.createCustomizedTimeline(
          data.timeline?.name,
          originalTimelineId,
          user?.lastSelectedAccount,
          user?.userId
        )
      )
      .then((timeline: { id: string }) => {
        redirectToResetTimeline(timeline.id);
        alertService.showOnNextPage('Leader Guide Reset');
      })
      .catch(handleError);
  };

  const [modal, openModal, _] = useModal((type: string, _: any, dismissModal: () => void) => {
    if (!type) return;

    return (
      <ConfirmationModal
        buttonActionText="Reset Leader Guide"
        buttonType="danger"
        title="Reset Leader Guide"
        prompt={
          <span>
            This will delete your notes, toggle all the sections on, and restore the original sequence of events.
          </span>
        }
        subtext={'Are you sure?'}
        handleSubmit={submitTimelineResetAndRedirect}
        handleDismiss={dismissModal}
      >
        <ModalWarningList warnings={['This action cannot be undone']} />
      </ConfirmationModal>
    );
  });

  useEffect(() => {
    Promise.all([
      getSessionFromSchedule(curriculumId, sessionId),
      sessionTimelineService.getPublishedAdminSessionTimeline(originalTimelineId, timelineId),
    ])
      .then(([session, timeline]) => {
        setData({ session, timeline, isLoading: false });
      })
      .catch((error: Error) => {
        console.error(error);
        setData({ isLoading: false, error });
      });
  }, []);

  return (
    <>
      <SessionTimelinePrintStyles options={brand.printOptions} />

      <BibleStudyNavigation crumbs={crumbs} curriculumId={curriculumId} activeTab="schedule" />
      <FullWidthCard>
        {data.isLoading || data.error ? (
          <Container style={{ paddingBottom: Grid._5 }}>
            {data.isLoading ? (
              <LoadingState />
            ) : data.error instanceof SessionNotFoundError ? (
              <SessionNotFound scheduleRoute={scheduleRoute} />
            ) : (
              data.error && <ErrorMessage />
            )}
          </Container>
        ) : (
          <>
            <Container data-qa-hook="manageTimelineViewContainer">
              <HelpAndInfoBox
                iconClass="fas fa-chalkboard-teacher"
                title="Session Leader Guide"
                description="Here you can customize this session’s leader guide to fit your church’s time by including or excluding activities. You can also add notes to activities to give your leaders additional information."
              />
              <SessionDateHeaderAndDetails session={data.session}>
                <p>{data.session?.getAvailableThroughMessage('MMMM d, yyyy')}</p>
              </SessionDateHeaderAndDetails>
              <SessionDescription
                dangerouslySetInnerHTML={{ __html: data.session?.description ?? '' }}
              ></SessionDescription>
            </Container>
            <SessionTimelineProvider
              timeline={data.timeline ?? {}}
              curriculum={{ brandCode: brand.code, name: ageCategory }}
              session={data.session}
            >
              <SessionTimeline
                customizable
                useViewPreferences={false}
                title={`Manage ${data.timeline?.name}`}
                subtitle="Manage session activities and add notes for your leaders"
                actionButtons={
                  <>
                    <PreviewButton
                      url={`/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/issue/${data.session?.issueId}/timeline/${originalTimelineId}/${timelineId}/preview`}
                    />
                    {presentationMediaArchiveName && (
                      <DownloadPresentationMediaButton
                        archiveName={presentationMediaArchiveName}
                        ButtonComponent={Button}
                      />
                    )}
                    <Button onClick={() => openModal('resetModal')}>Reset Leader Guide</Button>
                  </>
                }
              />
            </SessionTimelineProvider>
          </>
        )}
        {modal}
      </FullWidthCard>
    </>
  );
}
