import styled from 'styled-components';
import { Grid, Color, Border, Type, Breakpoints } from '../StyleGuide';

export const MaterialList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid ${Color.Blue._15};
  border-radius: ${Border.radius};
`;

type MaterialListItemProps = {
  disabled?: boolean;
  onClick?: () => void;
};
export const MaterialListItem = styled.li<MaterialListItemProps>`
  display: flex;
  align-items: center;
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._2};
  padding: ${Grid._3} ${Grid._4};
  min-height: ${Grid._8};
  & + li {
    border-top: 1px solid ${Color.Blue._15};
  }
  ${props =>
    props.onClick &&
    `
    &:not([disabled]):hover {
      background: ${Color.Gray._10};
      cursor: pointer;

      .material-name {
        text-decoration: underline;
      }
    }
  `}
  &[disabled] p {
    color: ${Type.Color.placeholder} !important;
  }
  div.material-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      flex-direction: row;
      margin-right: ${Grid._6};
    }
    p {
      margin: 0;
      &.material-label {
        width: 100%;
        text-transform: capitalize;
        color: ${Type.Color.medium};
        @media screen and (min-width: ${Breakpoints.screen_sm}) {
          width: 150px;
        }
      }
      &.material-name {
        flex: 1;
      }
    }
    ul {
      color: ${Type.Color.medium};
      flex: 2;
      margin-left: 0;
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        margin-left: ${Grid._4};
      }
    }
  }
  button > i {
    background: transparent;
  }
`;
